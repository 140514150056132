const initState = {
  loaded: true,
  data: [],
  errors: [],
  deletevisitorStatus: null,
};

const deleteVisitorReducer = (state = initState, action) => {
  switch (action.type) {
    case "DELETE_VISITOR_FROM_APPOINTMENT_REQUEST":
      return { ...state, loaded: false, deletevisitorStatus: null };
    case "DELETE_VISITOR_FROM_APPOINTMENT_SUCCESS":
      return {
        ...state,
        loaded: true,
        data: [...state.data, action.payload],
        deletevisitorStatus: "success",
      };
    case "DELETE_VISITOR_FROM_APPOINTMENT_FAILURE":
      return {
        ...state,
        loaded: true,
        errors: [...state.errors, action.payload],
        deletevisitorStatus: "failure",
      };
    default:
      return state;
  }
};

export default deleteVisitorReducer;
