const initState = {
  loaded: true,
  data: [],
  errors: [],
};

const getVisitorReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_VISITORS_REQUEST":
      return { ...state, loaded: false };
    case "GET_VISITORS_SUCCESS":
      return { ...state, loaded: true, data: action.payload, errors: [] };
    case "GET_VISITORS_FAILURE":
      return { ...state, loaded: true, errors: action.payload, data: [] };

    default:
      return state;
  }
};

export default getVisitorReducer;
