export const msalConfig = {
  auth: {
    clientId: "dbcc2a69-20ba-44f9-9bf9-6f9ceee57f3b",
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: "https://addin.eportier.ch",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export default msalConfig;
