import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../components/Input";

const Room = () => {
  const [room, setRoom] = useState("");
  const dispatch = useDispatch();

  const setRoomState = (roomName, roomEmail) => {
    setRoom(roomName);

    dispatch({
      type: "SET_APPOINTMENT_DATA",
      payload: { room: roomName },
    });
  };

  const getRoom = () => {
    Office.context.mailbox.item.enhancedLocation.getAsync((result) => {
      if (result.status !== Office.AsyncResultStatus.Succeeded) {
        console.error(`Failed to get locations. Error message: ${result.error.message}`);
        return;
      }

      const places = result.value;
      if (places && places.length > 0) {
        const roomLocation = places.find(
          (place) => place.locationIdentifier.type === Office.MailboxEnums.LocationType.Room,
        );
        if (roomLocation) {
          const roomName = roomLocation.displayName;
          const roomEmail = roomLocation.emailAddress;
          setRoomState(roomName, roomEmail);
        } else {
          setRoomState("", "");
        }
      } else {
        setRoomState("", "");
      }
    });
  };

  useEffect(() => {
    getRoom();

    const { addHandlerAsync, removeHandlerAsync } = Office.context.mailbox.item;
    const { EnhancedLocationsChanged } = Office.EventType;

    if (Office.context.mailbox.item) {
      addHandlerAsync(EnhancedLocationsChanged, getRoom);

      return () => {
        removeHandlerAsync(EnhancedLocationsChanged, getRoom);
      };
    }
    return () => {};
  }, []);

  return room ? <Input label="Raum" value={room} className="pointer-events-none" readOnly /> : null;
};

export default Room;
