const initState = {
  loaded: true,
  data: {
    visitors: [],
    host_email: null,
    location_name: null,
  },
  errors: [],
  isSuccessful: null,
};

const getAppointmentReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, data: { ...state.data, ...action.payload } };
    case "GET_APPOINTMENT_REQUEST":
      return { ...state, loaded: false };
    case "GET_APPOINTMENT_SUCCESS":
      return { ...state, loaded: true, data: { ...state.data, ...action.payload }, isSuccessful: true };
    case "GET_APPOINTMENT_FAILURE":
      return { ...state, loaded: true, errors: action.payload, isSuccessful: false };
    default:
      return state;
  }
};

export default getAppointmentReducer;
