const initState = {
  loaded: true,
  data: [],
  errors: [],
  changevisitorStatus: null,
};

const changeVisitorReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, data: action.payload };
    case "CHANGE_VISITOR_DATA_REQUEST":
      return { ...state, loaded: false };
    case "CHANGE_VISITOR_DATA_SUCCESS":
      return { ...state, loaded: true, data: action.payload, changevisitorStatus: "success" };
    case "CHANGE_VISITOR_DATA_FAILURE":
      return { ...state, loaded: true, errors: action.payload, changevisitorStatus: "failure" };
    default:
      return state;
  }
};

export default changeVisitorReducer;
