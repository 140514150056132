import axios from "axios";
import { call } from "redux-saga/effects";
import authSaga from "./authSaga";

function* graphAuthSaga(customerId, tokenType) {
  const URL = "https://api.screencom.ch";

  try {
    const accessToken = yield call(authSaga, { type: "Graph_Mail" });

    if (accessToken) {
      const response = yield axios.post(
        URL + "/token/list",
        {
          customer_id: 9,
          token_type: "Graph-Mail",
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      if (response.status !== 200) {
        throw new Error(`Failed to fetch access token from token list API: ${response.status} ${response.statusText}`);
      }

      return response.data.token;
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
}

export default graphAuthSaga;
