const initState = {
  loaded: true,
  data: [],
  errors: [],
  appointmentStatus: null,
};

const createAppointmentReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, data: action.payload };
    case "CREATE_APPOINTMENT_REQUEST":
      return { ...state, loaded: false };
    case "CREATE_APPOINTMENT_SUCCESS":
      return { ...state, loaded: true, data: action.payload, appointmentStatus: "success" };
    case "CREATE_APPOINTMENT_FAILURE":
      return { ...state, loaded: true, errors: action.payload, appointmentStatus: "failure" };
    default:
      return state;
  }
};

export default createAppointmentReducer;
