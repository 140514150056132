import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import Spinner from "./components/Spinner";
import LoginFailed from "./pages/LoginFailed";
import Portier from "./pages/Portier";
import { FETCH_PERMISSIONS_USER_REQUEST, GET_COMPANY_DATA_REQUEST } from "./redux/actions/index";

const App = ({ officeReady }) => {
  const [isTokenRequestInProgress, setIsTokenRequestInProgress] = useState(false);
  const [msalReady, setMsalReady] = useState(false);
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (officeReady && !isTokenRequestInProgress && msalReady) {
      trySSOLogin();
    }
  }, [officeReady, msalReady]);

  useEffect(() => {
    if (instance) {
      setMsalReady(true);
    }
  }, [instance]);

  const trySSOLogin = async () => {
    setIsTokenRequestInProgress(true);
    try {
      const token = await OfficeRuntime.auth.getAccessToken();
      dispatch(FETCH_PERMISSIONS_USER_REQUEST());
    } catch (error) {
      console.error("SSO Login fehlgeschlagen:", JSON.stringify(error));
      if (msalReady) {
        fallbackLogin();
      }
    } finally {
      setIsTokenRequestInProgress(false);
    }
  };

  const fallbackLogin = async () => {
    const loginRequest = {
      scopes: [
        "api://addin.eportier.ch/dbcc2a69-20ba-44f9-9bf9-6f9ceee57f3b/access_as_user",
        "openid",
        "profile",
        "email",
        "Mail.Send",
      ],
    };
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      dispatch(FETCH_PERMISSIONS_USER_REQUEST());
    } catch (msalError) {
      console.error("MSAL Login fehlgeschlagen:", msalError);
    }
  };

  const hasAccess =
    user.loaded &&
    user.data.permissions &&
    user.data.permissions.some((permission) =>
      ["Admin", "Visitor.Admin", "Visitor.Manager", "Visitor.User"].includes(permission.role_name),
    );

  useEffect(() => {
    if (user.data && hasAccess) {
      dispatch(GET_COMPANY_DATA_REQUEST({}));
    }
  }, [user.data, hasAccess]);

  return (
    <div>
      <Header />
      {user ? user.loaded ? hasAccess ? <Portier /> : <LoginFailed /> : <Spinner /> : <h1>Anmeldung erforderlich</h1>}
    </div>
  );
};

export default App;
