import * as React from "react";

const Header = () => {
  return (
    <div className="justify-center items-center">
        <img src="/assets/sc-eportier-logo.svg" className="w-full h-24" alt="SC ePortier" />
    </div>
  );
}

export default Header;
