import React from "react";

const AppointmentDeleted = () => {
  return (
    <div>
      <div className="flex h-[calc(100vh-160px)] items-center justify-center p-5 w-full bg-white">
        <div className="text-center">
          <div className="inline-flex rounded-full bg-green-100 p-6">
            <svg
              className="w-10 h-10 text-green-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m7 10 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
          <h1 className="mt-5 font-inter_bold text-2xl">Termin erfolgreich gelöscht</h1>
          <p className="font-inter_regular mt-5 text-base">
            Der Termin wurde für alle Teilnehmer erfolgreich entfernt.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDeleted;
