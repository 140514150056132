import { combineReducers } from "redux";
import addVisitorReducer from "./addVisitorReducer";
import appointmentReducer from "./appointmentReducer";
import changeAppointmentReducer from "./changeAppointmentReducer";
import changeVisitorReducer from "./changeVisitorReducer";
import companyReducer from "./companyReducer";
import createAppointmentReducer from "./createAppointmentReducer";
import deleteAppointmentReducer from "./deleteAppointmentReducer";
import deleteVisitorReducer from "./deleteVisitorReducer";
import getAppointmentReducer from "./getAppointmentReducer";
import getVisitorsReducer from "./getVisitorsReducer";
import mailReducer from "./mailReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  user: userReducer,
  company: companyReducer,
  appointment: appointmentReducer,
  createAppointment: createAppointmentReducer,
  visitors: getVisitorsReducer,
  mailstatus: mailReducer,
  getAppointment: getAppointmentReducer,
  deleteAppointment: deleteAppointmentReducer,
  changeAppointment: changeAppointmentReducer,
  changeVisitor: changeVisitorReducer,
  deleteVisitor: deleteVisitorReducer,
  addVisitor: addVisitorReducer,
});

export default rootReducer;
