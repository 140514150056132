import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../components/Input";

const DateTime = () => {
  const [date, setDate] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const dispatch = useDispatch();

  const updateDateTime = () => {
    Office.context.mailbox.item.start.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const appointmentStart = new Date(result.value);
        const formattedDate = appointmentStart.toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        const startHours = String(appointmentStart.getHours()).padStart(2, "0");
        const startMinutes = String(appointmentStart.getMinutes()).padStart(2, "0");
        const formattedStartTime = `${startHours}:${startMinutes}`;

        setDate(formattedDate);
        setArrivalTime(formattedStartTime);

        const dateTimeForRedux = `${appointmentStart.getFullYear()}-${String(appointmentStart.getMonth() + 1).padStart(
          2,
          "0",
        )}-${String(appointmentStart.getDate()).padStart(2, "0")}T${formattedStartTime}`;

        Office.context.mailbox.item.end.getAsync((endResult) => {
          if (endResult.status === Office.AsyncResultStatus.Succeeded) {
            const appointmentEnd = new Date(endResult.value);
            const formattedEndDate = appointmentEnd.toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const endHours = String(appointmentEnd.getHours()).padStart(2, "0");
            const endMinutes = String(appointmentEnd.getMinutes()).padStart(2, "0");
            const formattedEndTime = `${endHours}:${endMinutes}`;

            setEndDate(formattedEndDate);
            setEndTime(formattedEndTime);

            const endDateTimeForRedux = `${appointmentEnd.getFullYear()}-${String(
              appointmentEnd.getMonth() + 1,
            ).padStart(2, "0")}-${String(appointmentEnd.getDate()).padStart(2, "0")}T${formattedEndTime}`;

            dispatch({
              type: "SET_APPOINTMENT_DATA",
              payload: { date_time: dateTimeForRedux, end_date_time: endDateTimeForRedux },
            });
          } else {
            console.error(`End time fetch failed with message ${endResult.error.message}`);
          }
        });
      } else {
        console.error(`Start time fetch failed with message ${result.error.message}`);
      }
    });
  };

  useEffect(() => {
    updateDateTime();

    Office.context.mailbox.item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, updateDateTime);

    return () => {
      Office.context.mailbox.item.removeHandlerAsync(Office.EventType.AppointmentTimeChanged, updateDateTime);
    };
  }, []);

  return (
    <Input
      label="Datum und Zeit"
      value={`${date} ${arrivalTime} - ${endDate} ${endTime}`}
      className="pointer-events-none"
      readOnly
    />
  );
};

export default DateTime;
