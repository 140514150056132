export const SET_USER = (payload) => {
  return {
    type: "SET_USER",
    payload,
  };
};

export const FETCH_PERMISSIONS_USER_REQUEST = (payload) => {
  return { type: "FETCH_PERMISSIONS_USER_REQUEST", payload };
};

export const FETCH_PERMISSIONS_USER_SUCCESS = (payload) => {
  return { type: "FETCH_PERMISSIONS_USER_SUCCESS", payload };
};

export const FETCH_PERMISSIONS_USER_FAILURE = (payload) => {
  return { type: "FETCH_PERMISSIONS_USER_FAILURE", payload };
};

export const GET_HOSTS_REQUEST = (payload) => {
  return { type: "GET_HOSTS_REQUEST", payload };
};

export const GET_HOSTS_SUCCESS = (payload) => {
  return { type: "GET_HOSTS_SUCCESS", payload };
};

export const GET_HOSTS_FAILURE = (payload) => {
  return { type: "GET_HOSTS_FAILURE", payload };
};

export const CREATE_USER_REQUEST = (payload) => {
  return { type: "CREATE_USER_REQUEST", payload };
};

export const CREATE_USER_SUCCESS = (payload) => {
  return { type: "CREATE_USER_SUCCESS", payload };
};

export const CREATE_USER_FAILURE = (payload) => {
  return { type: "CREATE_USER_FAILURE", payload };
};

export const CREATE_APPOINTMENT_REQUEST = (payload) => {
  return { type: "CREATE_APPOINTMENT_REQUEST", payload };
};

export const CREATE_APPOINTMENT_SUCCESS = (payload) => {
  return { type: "CREATE_APPOINTMENT_SUCCESS", payload };
};

export const CREATE_APPOINTMENT_FAILURE = (payload) => {
  return { type: "CREATE_APPOINTMENT_FAILURE", payload };
};

export const CHANGE_APPOINTMENT_REQUEST = (payload) => {
  return { type: "CHANGE_APPOINTMENT_REQUEST", payload };
};

export const CHANGE_APPOINTMENT_SUCCESS = (payload) => {
  return { type: "CHANGE_APPOINTMENT_SUCCESS", payload };
};

export const CHANGE_APPOINTMENT_FAILURE = (payload) => {
  return { type: "CHANGE_APPOINTMENT_FAILURE", payload };
};

export const CHANGE_VISITOR_DATA_REQUEST = (payload) => {
  return { type: "CHANGE_VISITOR_DATA_REQUEST", payload };
};

export const CHANGE_VISITOR_DATA_SUCCESS = (payload) => {
  return { type: "CHANGE_VISITOR_DATA_SUCCESS", payload };
};

export const CHANGE_VISITOR_DATA_FAILURE = (payload) => {
  return { type: "CHANGE_VISITOR_DATA_FAILURE", payload };
};

export const DELETE_VISITOR_FROM_APPOINTMENT_REQUEST = (payload) => {
  return { type: "DELETE_VISITOR_FROM_APPOINTMENT_REQUEST", payload };
};

export const DELETE_VISITOR_FROM_APPOINTMENT_SUCCESS = (payload) => {
  return { type: "DELETE_VISITOR_FROM_APPOINTMENT_SUCCESS", payload };
};

export const DELETE_VISITOR_FROM_APPOINTMENT_FAILURE = (payload) => {
  return { type: "DELETE_VISITOR_FROM_APPOINTMENT_FAILURE", payload };
};

export const ADD_VISITOR_TO_APPOINTMENT_REQUEST = (payload) => {
  return { type: "ADD_VISITOR_TO_APPOINTMENT_REQUEST", payload };
};

export const ADD_VISITOR_TO_APPOINTMENT_SUCCESS = (payload) => {
  return { type: "ADD_VISITOR_TO_APPOINTMENT_SUCCESS", payload };
};

export const ADD_VISITOR_TO_APPOINTMENT_FAILURE = (payload) => {
  return { type: "ADD_VISITOR_TO_APPOINTMENT_FAILURE", payload };
};

export const GET_APPOINTMENT_REQUEST = (payload) => {
  return { type: "GET_APPOINTMENT_REQUEST", payload };
};

export const GET_APPOINTMENT_SUCCESS = (payload) => {
  return { type: "GET_APPOINTMENT_SUCCESS", payload };
};

export const GET_APPOINTMENT_FAILURE = (payload) => {
  return { type: "GET_APPOINTMENT_FAILURE", payload };
};
export const GET_COMPANY_DATA_REQUEST = (payload) => {
  return { type: "GET_COMPANY_DATA_REQUEST", payload };
};

export const GET_COMPANY_DATA_SUCCESS = (payload) => {
  return { type: "GET_COMPANY_DATA_SUCCESS", payload };
};

export const GET_COMPANY_DATA_FAILURE = (payload) => {
  return { type: "GET_COMPANY_DATA_FAILURE", payload };
};

export const WRITE_LOG_REQUEST = () => {
  return { type: "WRITE_LOG_REQUEST" };
};

export const WRITE_LOG_SUCCESS = (payload) => {
  return { type: "WRITE_LOG_SUCCESS", payload };
};

export const WRITE_LOG_FAILURE = (payload) => {
  return { type: "WRITE_LOG_FAILURE", payload };
};

export const GET_VISITORS_REQUEST = () => {
  return { type: "GET_VISITORS_REQUEST" };
};

export const GET_VISITORS_SUCCESS = (payload) => {
  return { type: "GET_VISITORS_SUCCESS", payload };
};

export const GET_VISITORS_FAILURE = (payload) => {
  return { type: "GET_VISITORS_FAILURE", payload };
};

export const SET_APPOINTMENT_DATA = (data) => ({
  type: "SET_APPOINTMENT_DATA",
  payload: data,
});

export const UPDATE_VISITOR_SELECTION = (visitorData) => ({
  type: "UPDATE_VISITOR_SELECTION",
  payload: visitorData,
});

export const SET_AUTH_METHOD = (method) => ({
  type: "SET_AUTH_METHOD",
  payload: method,
});
