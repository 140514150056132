import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { call, put, select } from "redux-saga/effects";
import pcaInstance from "../../login/msalInstance";

let isTokenRequestInProgress = false;

function* authSaga(action) {
  while (isTokenRequestInProgress) {
    yield new Promise((resolve) => setTimeout(resolve, 100));
  }

  isTokenRequestInProgress = true;

  const preferredAuthMethod = yield select((state) => state.user.authMethod);

  try {
    if (preferredAuthMethod === "SSO") {
      try {
        const officeToken = yield call([OfficeRuntime.auth, OfficeRuntime.auth.getAccessToken]);

        if (officeToken) {
          isTokenRequestInProgress = false;
          return officeToken;
        }
      } catch (officeError) {
        console.log("SSO via OfficeRuntime fehlgeschlagen, setze Authentifizierungsmethode auf MSAL", officeError);
        yield put({ type: "SET_AUTH_METHOD", payload: "MSAL" });
      }
    }

    const accounts = pcaInstance.getAllAccounts();
    if (accounts.length > 0) {
      const request = {
        scopes: [
          "api://addin.eportier.ch/dbcc2a69-20ba-44f9-9bf9-6f9ceee57f3b/access_as_user",
          "openid",
          "profile",
          "email",
          "Mail.Send",
        ],
        account: accounts[0],
      };

      try {
        const response = yield call([pcaInstance, pcaInstance.acquireTokenSilent], request);
        const accessToken = response.accessToken;
        isTokenRequestInProgress = false;
        return accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          sessionStorage.setItem("pendingAction", JSON.stringify(action));
          yield call([pcaInstance, pcaInstance.acquireTokenRedirect], request);
        } else {
          throw error;
        }
      }
    }
  } catch (error) {
    console.error("Fehler bei der Authentifizierung", error);
    throw error;
  } finally {
    isTokenRequestInProgress = false;
  }
}

export default authSaga;
