const initialState = {
  emailStatus: null, // 'success', 'failure', oder null
};

const mailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SEND_EMAIL_SUCCESS":
      return {
        ...state,
        emailStatus: "success",
      };
    case "SEND_EMAIL_FAILURE":
      return {
        ...state,
        emailStatus: "failure",
      };
    default:
      return state;
  }
};

export default mailReducer;
