import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_COMPANY_DATA_FAILURE,
  GET_COMPANY_DATA_SUCCESS,
  GET_HOSTS_FAILURE,
  GET_HOSTS_SUCCESS,
} from "../actions/index";
import authSaga from "./authSaga";

const URL = "https://api.screencom.ch";

export function* FetchCompanyData(action) {
  try {
    const accessToken = yield call(authSaga, { type: "FETCH_PERMISSIONS_USER_REQUEST" });
    if (accessToken) {
      const accessToken = yield call(authSaga, { type: "GET_COMPANY_DATA_REQUEST" });
      const res = yield axios.post(URL + "/api/load_company_data", action.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      yield put(GET_COMPANY_DATA_SUCCESS(res.data[1]));
    } else {
      throw new Error("Failed to fetch access token for company data.");
    }
  } catch (err) {
    yield put(GET_COMPANY_DATA_FAILURE(err));
  }
}

export function* FetchHost(action) {
  try {
    const accessToken = yield call(authSaga, { type: "GET_HOSTS_REQUEST" });
    if (accessToken) {
      const accessToken = yield call(authSaga, { type: "GET_HOSTS_REQUEST" });
      const res = yield axios.post(URL + "/teams/hosts", action.payload.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      yield put(GET_HOSTS_SUCCESS(res.data.host));
    } else {
      throw new Error("Failed to fetch access token for host.");
    }
  } catch (err) {
    yield put(GET_HOSTS_FAILURE(err));
  }
}

export default function* companySaga() {
  yield takeLatest("GET_COMPANY_DATA_REQUEST", FetchCompanyData);
  yield takeLatest("GET_HOSTS_REQUEST", FetchHost);
}
