import { call, put, select, takeLatest } from "redux-saga/effects";
import sendEmailUsingGraphSaga from "../../helpers/graphHelpers";
import axios from "axios";
import authSaga from "./authSaga";

const getCustomerName = (state) => state.company.data.customer_name;
const getUserToken = (state) => state.user.token;

function* fetchAccessToken() {
  let token = yield select(getUserToken);

  if (!token) {
    token = yield call(authSaga, { type: "GET_COMPANY_DATA_REQUEST" });
    if (token) {
      yield put({ type: "SET_TOKEN", payload: token });
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  }

  return token;
}

function* sendEmailUsingACSSaga(visitors, customerName) {
  try {
    const token = yield call(fetchAccessToken);

    for (const visitor of visitors) {
      const email = {
        recipient_mail: visitor.email,
        recipient_name: visitor.name || "Visitor",
        subject: `Sie sind zu ${customerName} eingeladen`,
        content: visitor.email_with_qr_code,
        qr_code: visitor.qr_code_base64,
        logo: visitor.logo_base64,
      };

      const url = "https://api.screencom.ch/user/mail";
      const response = yield call(axios.post, url, email, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });

      if (response.status !== 200) {
        const errorText = response.statusText;
        console.error("Fehler beim Senden der E-Mail über die ACS API:", errorText);
        throw new Error(`ACS API Fehler: ${response.status}`);
      }
    }
  } catch (error) {
    console.error("Fehler bei der ACS API oder beim E-Mail-Versand", error);
    throw error;
  }
}

function* sendEmail(action) {
  try {
    const customerName = yield select(getCustomerName);

    if (action.payload[0] === true) {
      const visitors = action.payload[2].visitors;

      try {
        yield call(sendEmailUsingGraphSaga, visitors, customerName);
        yield put({ type: "SEND_EMAIL_SUCCESS" });
      } catch (graphError) {
        console.error("Graph API failed, trying ACS:", graphError);
        try {
          yield call(sendEmailUsingACSSaga, visitors, customerName);
          yield put({ type: "SEND_EMAIL_SUCCESS" });
        } catch (acsError) {
          console.error("Both Graph API and ACS failed:", acsError);
          yield put({ type: "SEND_EMAIL_FAILURE" });
        }
      }
    }
  } catch (error) {
    console.error("Failed to send email:", error);
    yield put({ type: "SEND_EMAIL_FAILURE" });
  }
}

export function* watchCreateAppointmentSuccess() {
  yield takeLatest("CREATE_APPOINTMENT_SUCCESS", sendEmail);
  yield takeLatest("ADD_VISITOR_TO_APPOINTMENT_SUCCESS", sendEmail);
}
