import { all } from "redux-saga/effects";
import appointmentSaga from "./appointmentSaga";
import companySaga from "./companySaga";
import { watchCreateAppointmentSuccess } from "./sendMailSaga";
import userSaga from "./userSaga";
import { visitorsDataSaga } from "./visitorsDataSaga";

export default function* rootSaga() {
  yield all([visitorsDataSaga(), userSaga(), companySaga(), appointmentSaga(), watchCreateAppointmentSuccess()]);
}
