import * as React from "react";

const Checkbox = ({ label, className, ...props }) => {
  return (
    <div className="block space-y-3 my-1 mt-2">
      <div className="relative flex">
        <div className="flex justify-center items-center">
          <input type="checkbox" className={`w-4 h-4 accent-black ${className}`} {...props} />
        </div>
        <label className="ml-3 flex items-center justify-center">
          <div className="flex flex-col">
            <span
              className={`block text-sm font-inter_regular ${className}`}
              style={{ whiteSpace: "normal", wordBreak: "break-word" }}
            >
              {label}
            </span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
