const initState = {
  loaded: true,
  data: { hosts: [] },
  errors: [],
};

const companyReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, data: action.payload };
    case "GET_COMPANY_DATA_REQUEST":
      return { ...state, loaded: false };
    case "GET_COMPANY_DATA_SUCCESS":
      return { ...state, loaded: true, data: action.payload };
    case "GET_COMPANY_DATA_FAILURE":
      return { ...state, loaded: true, errors: action.payload };
    case "GET_HOSTS_REQUEST":
      return { ...state, loaded: false };
    case "GET_HOSTS_SUCCESS":
      return { ...state, loaded: true, data: { ...state.data, hosts: action.payload } };
    case "GET_HOSTS_FAILURE":
      return { ...state, loaded: true, errors: action.payload };
    case "ADD_HOST":
      const hosts = state.data.hosts || [];
      const hostExists = hosts.some((host) => host.email === action.payload.email);
      if (!hostExists) {
        return {
          ...state,
          data: {
            ...state.data,
            hosts: [...hosts, action.payload],
          },
        };
      }
      return state;
    default:
      return state;
  }
};

export default companyReducer;
