import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Toggle from "react-toggle";
import Checkbox from "./Checkbox";
import Dropdown from "./Dropdown";
import Input from "./Input";
import VisitorInput from "./VisitorInput";

const convertDropDownValues = (data) => {
  let values = [];
  data.forEach((item) => values.push({ label: item, value: item }));
  return values;
};

const Visitor = (props, ref) => {
  const [isChecked, setIsChecked] = useState(props.initiallyChecked || false);
  const [name, setName] = useState(props.initialName !== props.initialEmail ? props.initialName : "");
  const [email, setEmail] = useState(props.initialEmail || "");
  const [company, setCompany] = useState(props.initialCompany || "");
  const [dynamicFields, setDynamicFields] = useState(
    props.dynamicFields.map((field) => ({
      ...field,
      value: field.inserted_value || "",
      isValid: true,
    })),
  );
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    setDynamicFields(
      props.dynamicFields.map((field) => ({
        ...field,
        value: field.inserted_value || "",
        isValid: true,
      })),
    );
  }, [props.dynamicFields]);

  const handleInputChange = (index, value) => {
    setDynamicFields((prevFields) =>
      prevFields.map((field, idx) => {
        if (idx === index) {
          return { ...field, value: value, isValid: true, inserted_value: value };
        }
        return field;
      }),
    );
  };

  const triggerValidation = () => {
    if (!isChecked) {
      return { isValid: true, missingFields: [] };
    }

    let isValid = true;
    let missingFields = [];

    if (!name.trim()) {
      isValid = false;
      missingFields.push("Name");
    }
    if (!email.trim()) {
      isValid = false;
      missingFields.push("Email");
    }

    const updatedFields = dynamicFields.map((field) => {
      const fieldType = field.type || field.info_type;

      if (fieldType === "required" || fieldType === "required-add-in") {
        if (field.field_type === "checkbox" || field.field_type === "toggle") {
          if (field.value !== "on") {
            isValid = false;
            missingFields.push(field.title);
            return { ...field, isValid: false };
          }
        } else if (!field.value.trim()) {
          isValid = false;
          missingFields.push(field.title);
          return { ...field, isValid: false };
        }
        return { ...field, isValid: true };
      }
      return field;
    });

    setDynamicFields(updatedFields);
    console.log("Validation completed in Visitor component:", isValid, missingFields);

    setShowValidation(true);
    return { isValid, missingFields };
  };

  const getVisitorData = () => ({
    isChecked,
    name,
    email,
    company,
    dynamicFields,
  });

  useImperativeHandle(ref, () => ({
    triggerValidation,
    getVisitorData,
  }));

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const renderField = (field, index) => {
    const { title, field_type, accepted_values, type, info_type, isValid } = field;
    const isRequired =
      type === "required" || type === "required-add-in" || info_type === "required" || info_type === "required-add-in";
    const value = field.value;

    const labelClassName = isValid ? "" : "text-red-500 font-semibold";

    switch (field_type) {
      case "checkbox":
        return (
          <div key={index} className="flex items-center cursor-pointer my-2">
            <Checkbox
              label={title}
              checked={value === "on"}
              onChange={(e) => handleInputChange(index, e.target.checked ? "on" : "off")}
              required={isRequired}
              className={labelClassName}
            />
          </div>
        );
      case "toggle":
        return (
          <div key={index} className="flex items-center cursor-pointer my-2">
            <Toggle
              icons={false}
              checked={value === "on"}
              onChange={(e) => handleInputChange(index, e.target.checked ? "on" : "off")}
              required={isRequired}
            />
            <span
              className={`block ml-3 text-sm font-inter_regular ${labelClassName}`}
              style={{ whiteSpace: "normal", wordBreak: "break-word" }}
            >
              {title}
            </span>
          </div>
        );
      case "text":
        return (
          <Input
            key={index}
            placeholder={title}
            type="text"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            required={isRequired}
            className={isValid ? "" : "border-red-500"}
          />
        );
      case "number":
        return (
          <Input
            key={index}
            placeholder={title}
            type="number"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            required={isRequired}
            className={isValid ? "" : "border-red-500"}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            key={index}
            options={convertDropDownValues(field.accepted_values)}
            value={dynamicFields[index].value}
            onChange={(value) => handleInputChange(index, value)}
            required={isRequired}
            placeholder={title}
            className={isValid ? "" : "border-red-500"}
          />
        );
      default:
        return null;
    }
  };

  const isNameSameAsEmail = name === email;
  const nameError = showValidation && (!name || isNameSameAsEmail);

  return (
    <div className="grid space-y-3 mt-4">
      <div className="p-2 ring-1 ring-lightgrey rounded-md">
        <div className="relative flex">
          <div className="flex justify-center items-center h-10">
            <input
              id="default-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="w-4 h-4 bg-gray-100 border-gray-300 accent-black rounded focus:ring-blue-500"
            />
          </div>
          <label className="ml-3 flex items-center justify-center">
            <div className="flex flex-col">
              {name && !isNameSameAsEmail && <span className="block text-sm font-inter_semibold">{name}</span>}
              <span id="hs-checkbox-delete-description" className="block text-sm font-inter_regular">
                {email || "E-Mail Adresse"}
              </span>
            </div>
          </label>
        </div>
        {isChecked && (
          <div>
            <VisitorInput
              placeholder="Firma"
              value={company}
              onChange={(event) => {
                const newCompany = event.target.value;
                setCompany(newCompany);
              }}
            />
            <VisitorInput
              placeholder="Name"
              value={isNameSameAsEmail ? "" : name}
              className={showValidation && (!name || isNameSameAsEmail) ? "border-red-500" : ""}
              onChange={(event) => {
                const newName = event.target.value;
                setName(newName);
              }}
            />
            <VisitorInput
              placeholder="E-Mail Adresse"
              value={email}
              className={showValidation && !email.trim() ? "border-red-500" : ""}
              onChange={(event) => {
                const newEmail = event.target.value;
                setEmail(newEmail);
              }}
              disabled
            />
            {dynamicFields.filter((field) => field.is_shown.includes("Outlook")).map(renderField)}
          </div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(Visitor);
