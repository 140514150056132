const initialState = {
  deleteAppointmentStatus: null,
};

const deleteAppointmentReducer = (state = initialState, action) => {
  // console.log("Reducer called with action", action);
  switch (action.type) {
    case "DELETE_APPOINTMENT_SUCCESS":
      return {
        ...state,
        deleteAppointmentStatus: "success",
      };
    case "DELETE_APPOINTMENT_FAILURE":
      return {
        ...state,
        deleteAppointmentStatus: "failure",
      };
    default:
      return state;
  }
};

export default deleteAppointmentReducer;
