import axios from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAILURE,
  GET_APPOINTMENT_SUCCESS,
} from "../actions/index";
import authSaga from "./authSaga";

const URL = "https://api.screencom.ch";

export function* createAppointment(action) {
  try {
    const accessToken = yield call(authSaga, { type: "CREATE_APPOINTMENT_REQUEST" });

    if (accessToken) {
      const res = yield axios.post(URL + "/api/create_appointment", action.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      yield put(CREATE_APPOINTMENT_SUCCESS(res.data));
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (err) {
    yield put(CREATE_APPOINTMENT_FAILURE(err));
  }
}

export function* deleteAppointment(action) {
  try {
    const config = {
      data: { outlook_id: action.payload.outlook_id },
      headers: { Authorization: "" },
    };
    const accessToken = yield call(authSaga, { type: "DELETE_APPOINTMENT_REQUEST" });

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;

      const res = yield axios.delete(URL + "/api/delete_appointment", config);
      //console.log("API response:", res);
      yield put({ type: "DELETE_APPOINTMENT_SUCCESS", payload: res.data });
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (err) {
    console.error("Error in deleteAppointment:", err);
    yield put({ type: "DELETE_APPOINTMENT_FAILURE", payload: err });
  }
}

export function* changeAppointment(action) {
  try {
    const accessToken = yield call(authSaga, { type: "CHANGE_APPOINTMENT_REQUEST" });

    if (accessToken) {
      const res = yield axios.patch(URL + "/api/change_appointment_data", action.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      //console.log("API response:", res);
      yield put({ type: "CHANGE_APPOINTMENT_SUCCESS", payload: res.data });
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (err) {
    yield put({ type: "CHANGE_APPOINTMENT_FAILURE", payload: err });
  }
}

export function* changeVisitor(action) {
  try {
    const accessToken = yield call(authSaga, { type: "CHANGE_VISITOR_DATA_REQUEST" });

    //console.log("CHANGEVISITOR", action.payload);

    if (accessToken) {
      const res = yield axios.patch(URL + "/user/change_visitor_data", action.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      //console.log("API response:", res);
      yield put({ type: "CHANGE_VISITOR_DATA_SUCCESS", payload: res.data });
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (err) {
    yield put({ type: "CHANGE_VISITOR_DATA_FAILURE", payload: err });
  }
}

export function* addVisitor(action) {
  try {
    const accessToken = yield call(authSaga, { type: "ADD_VISITOR_TO_APPOINTMENT_REQUEST" });

    if (accessToken) {
      const res = yield axios.post(URL + "/user/add_visitor_to_appointment", action.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      //console.log("API response:", res);
      yield put({ type: "ADD_VISITOR_TO_APPOINTMENT_SUCCESS", payload: res.data });
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (err) {
    yield put({ type: "ADD_VISITOR_TO_APPOINTMENT_FAILURE", payload: err });
  }
}

export function* deleteVisitor(action) {
  try {
    const { outlook_id, visitor_id } = action.payload;
    const accessToken = yield call(authSaga, { type: "DELETE_VISITOR_FROM_APPOINTMENT_REQUEST" });

    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
        data: { outlook_id, visitor_id },
      };

      const res = yield call(axios.delete, `${URL}/user/delete_visitor_from_appointment`, config);
      //console.log("API response:", res);

      if (res.status === 200) {
        yield put({ type: "DELETE_VISITOR_FROM_APPOINTMENT_SUCCESS", payload: { visitor_id } });
      } else {
        throw new Error("Failed to delete visitor.");
      }
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (err) {
    console.error("Error in deleteVisitor:", err);
    yield put({ type: "DELETE_VISITOR_FROM_APPOINTMENT_FAILURE", payload: err.message });
  }
}

export function* getAppointment(action) {
  try {
    const accessToken = yield call(authSaga, { type: "GET_APPOINTMENT_REQUEST" });

    if (accessToken) {
      const res = yield axios.post(URL + "/api/get_appointment", action.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      yield put(GET_APPOINTMENT_SUCCESS(res.data.data));
      //console.log("GET_APPOINTMENT", res.data);
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (err) {
    yield put(GET_APPOINTMENT_FAILURE(err));
  }
}

export default function* appointmentSaga() {
  yield takeLatest("CHANGE_APPOINTMENT_REQUEST", changeAppointment);
  yield takeLatest("CREATE_APPOINTMENT_REQUEST", createAppointment);
  yield takeLatest("DELETE_APPOINTMENT_REQUEST", deleteAppointment);
  yield takeLatest("CHANGE_VISITOR_DATA_REQUEST", changeVisitor);
  yield takeLatest("ADD_VISITOR_TO_APPOINTMENT_REQUEST", addVisitor);
  yield takeEvery("DELETE_VISITOR_FROM_APPOINTMENT_REQUEST", deleteVisitor);
  yield takeLatest("GET_APPOINTMENT_REQUEST", getAppointment);
}
