const initState = {
  loaded: true,
  data: [],
  errors: [],
  addvisitorStatus: null,
};

const addVisitorReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_VISITOR_TO_APPOINTMENT_REQUEST":
      return { ...state, loaded: false };
    case "ADD_VISITOR_TO_APPOINTMENT_SUCCESS":
      return { ...state, loaded: true, data: action.payload, addvisitorStatus: "success" };
    case "ADD_VISITOR_TO_APPOINTMENT_FAILURE":
      return { ...state, loaded: true, errors: action.payload, addvisitorStatus: "failure" };
    default:
      return state;
  }
};

export default addVisitorReducer;
