import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function useFetchOutlookIdOnLoad() {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAndStoreOutlookId(dispatch);
  }, [dispatch]);
}

export function fetchAndStoreOutlookId(dispatch) {
  getEventOutlookUid((err, eventOutlookUid) => {
    if (err) {
      console.error("Error fetching Outlook UID " + err.message);
    } else if (eventOutlookUid) {
      console.log("ID", eventOutlookUid);
      dispatch(setAppointmentData(eventOutlookUid));
    } else {
      Office.context.mailbox.item.saveAsync(function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          console.log(`saveAsync succeeded, itemId is ${result.value}`);
          dispatch(setAppointmentData(result.value));
        } else {
          console.error(`saveAsync failed with message ${result.error.message}`);
        }
      });
    }
  });
}

function setAppointmentData(outlookId) {
  return {
    type: "SET_APPOINTMENT_DATA",
    payload: {
      outlook_id: outlookId,
    },
  };
}

function getEventOutlookUid(callback) {
  if (typeof Office.context.mailbox.item.getItemIdAsync === "function") {
    Office.context.mailbox.item.getItemIdAsync(function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        callback(null, result.value);
      } else {
        console.warn(`EventOutlookUid unavailable: ${result.error.message}. Probably just a new event`);
        callback(null, null);
      }
    });
  } else if (Office.context.mailbox.item.itemId) {
    callback(null, Office.context.mailbox.item.itemId);
  } else {
    callback(
      new Error(
        "Neither Office.context.mailbox.item.getItemIdAsync nor Office.context.mailbox.item.itemId could get Outlook Item UID",
      ),
      null,
    );
  }
}
