import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import authSaga from "./authSaga";

const URL = "https://api.screencom.ch";

function* onFetchPermissions(action) {
  try {
    const accessToken = yield call(authSaga, { type: "FETCH_PERMISSIONS_USER_REQUEST" });

    if (accessToken) {
      const response = yield axios.get(URL + "/user/permissions", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      yield put({ type: "FETCH_PERMISSIONS_USER_SUCCESS", payload: response.data });
    } else {
      throw new Error("Failed to fetch access token for user permissions.");
    }
  } catch (error) {
    console.error("Error fetching user permissions:", error);
    yield put({ type: "FETCH_PERMISSIONS_USER_FAILURE", error });
  }
}

export default function* userSaga() {
  yield takeLatest("FETCH_PERMISSIONS_USER_REQUEST", onFetchPermissions);
}
